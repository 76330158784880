export const JobRequestTypes = {
  GET_JOB_REQUEST_DETAILS_REQUESTED: "jobRequest/GET_JOB_REQUEST_DETAILS_REQUESTED",
  GET_JOB_REQUEST_DETAILS_SUCCESS: "jobRequest/GET_JOB_REQUEST_DETAILS_SUCCESS",
  GET_JOB_REQUEST_DETAILS_FAILURE: "jobRequest/GET_JOB_REQUEST_DETAILS_FAILURE",

  UPDATE_JOB_REQUEST_REQUESTED: "jobRequest/UPDATE_JOB_REQUEST_REQUESTED",
  UPDATE_JOB_REQUEST_SUCCESS: "jobRequest/UPDATE_JOB_REQUEST_SUCCESS",
  UPDATE_JOB_REQUEST_FAILURE: "jobRequest/UPDATE_JOB_REQUEST_FAILURE",

  GET_APPROVALS_DATA_REQUESTED: "jobRequest/GET_APPROVALS_DATA_REQUESTED",
  GET_APPROVALS_DATA_SUCCESS: "jobRequest/GET_APPROVALS_DATA_SUCCESS",
  GET_APPROVALS_DATA_FAILURE: "jobRequest/GET_APPROVALS_DATA_FAILURE",

  JOB_REQUEST_REQUEST: "jobRequest/JOB_REQUEST_REQUEST",
  JOB_REQUEST_FAILURE: "jobRequest/JOB_REQUEST_FAILURE",

  GET_COMPARE_QUOTATION_SUCCESS: "jobRequest/GET_COMPARE_QUOTATION_SUCCESS",
  UPDATE_CLIENT_REQUEST_SUCCESS: "jobRequest/UPDATE_CLIENT_REQUEST_SUCCESS",
  UPLOAD_QUOTE_COMPARISON: "jobRequest/UPLOAD_QUOTE_COMPARISON",

  // reports
  GET_ALL_CUSTOM_REPORTS_REQUESTED: "jobRequest/GET_ALL_CUSTOM_REPORTS_REQUESTED",
  GET_ALL_CUSTOM_REPORTS_SUCCESS: "jobRequest/GET_ALL_CUSTOM_REPORTS_SUCCESS",
  GET_ALL_CUSTOM_REPORTS_FAILURE: "jobRequest/GET_ALL_CUSTOM_REPORTS_FAILURE",

  RESET_CUSTOM_REPORTS: "jobRequest/RESET_CUSTOM_REPORTS",

  UPDATE_LAST_ACTIVITY_DATE: "jobRequest/UPDATE_LAST_ACTIVITY_DATE",

  PUBLISH_DOCS: "jobRequest/PUBLISH_DOCS",

  GET_JOB_QUOTATIONS: "jobRequest/GET_JOB_QUOTATIONS",
  
  GET_JOB_DOCS: "jobRequest/GET_JOB_DOCS",
};
