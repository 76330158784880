import styled from "styled-components";

export const QuotationTabContainer = styled.div`
  letter-spacing: 0.01em;
  color: #011759;
`;

export const QuotationTableHeaderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const QuotationTableHeader = styled.div<any>`
  width: ${(props) => props?.width};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
`;

export const QuotationDataRow = styled.div`
  display: flex;
  line-height: 21px;
  background: #c4cbe6;
  padding: 10px
`;

export const QuotationsContainer = styled.div`
`;

export const QuotationData = styled.div<any>`
  width: ${(props) => props?.width};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 20px 20px 0 0;
  font-weight: 500;
  opacity: 0.7;
  line-height: 22px;
  text-transform: ${(props) => props?.shouldCapitalize && "capitalize"};
  text-decoration-line: ${(props) => props?.strikeThrough && "line-through"};
`;
export const CheckBoxData = styled.div`
  width: 5%;
  padding: 20px 20px 0 0;
`;

export const DownloadLink = styled.span`
  font-weight: 600;
  text-align: right;
  color: #215ec9;
  padding: 20px 20px 0 0;
  cursor: pointer;
`;

export const CompareQuotationContainer =  styled.div`
width:100%`;

export const UploadFile  = styled.a`
  width: 160px;
  text-align: center;
  word-break: break-all;
  margin-left: 10px;
  border-radius: 3px;
  padding: 8px 15px;
  background: #e3e7f5;
  color: #011759;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
    `;
  export const FileName = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `;
