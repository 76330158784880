export enum NAVIGATIONS {
  VENDORS = "Vendors",
  CLIENTS = "Clients",
  CLIENT_REQUESTS = "Client Requests",
  CLIENT_ANALYTICS = "Client Analytics",
  VENDOR_ANALYTICS = "Vendor Analytics",
  COMMUNICATIONS = "Communications",
  MESSAGING = "Messaging",
  USER_INVITES = "User Invites",
  TAGS = "Tags",
  SETTINGS = "Settings",
  JOB_DOCS = "Job Docs"
}